/* eslint-disable @typescript-eslint/camelcase */
import {graphql, useStaticQuery} from 'gatsby';
import useLocations, {Location} from './useLocations';
import useTeams, {Team} from './useTeams';
import alphaSort from './alphaSort';

type CMSPage = Location | Team;

interface SpecialCasePage {
  id: string;
  title: string;
}

interface ForeignRelation {
  name: string;
  alternative_id: number;
}

interface JobProps {
  title: string;
  alternative_id: number;
  content: string;
  departments: ForeignRelation[];
  offices: ForeignRelation[];
  updated_at: string;
}

export type FormattedCMSData<T extends CMSPage | SpecialCasePage> = T & {
  id: string;
  name: string;
  text: string;
  value: string;
  slug: string | null;
  jobs: FormattedJob[];
};

interface FormattedJob {
  id: number;
  title: string;
  content: string;
  path: string;
  team: string;
  teamId: string;
  teamPath: string;
  location: string;
  locationId: string;
  locationPath: string;
  address1: string;
  address2: string;
  abbreviation: string;
  phone: string;
  updated_at: string;
  locations?: LocationsProps[];
}

interface UniqueJobObject {
  [key: string]: UniqueJobProps;
}

export interface LocationsProps {
  location: string;
  locationId: string;
  locationPath: string;
  address1: string;
  address2: string;
  abbreviation: string;
  phone: string;
}
interface UniqueJobProps extends FormattedJob {
  locations: LocationsProps[];
}

interface FormattedData {
  teams: FormattedCMSData<Team>[];
  allTeamsWithSpecialCases: FormattedCMSData<SpecialCasePage>[];
  locations: FormattedCMSData<Location>[];
  allJobs: FormattedJob[];
}

export interface GreenHouseData {
  allJobs: FormattedJob[];
  teams: FormattedCMSData<Team>[];
  allTeamsWithSpecialCases: FormattedCMSData<SpecialCasePage>[];
  locations: FormattedCMSData<Location>[];
  getJob: (jobId: number) => FormattedJob | undefined | null;
  getTeam: (teamId: string) => FormattedCMSData<Team> | undefined | null;
  getLocation: (
    locationId: string,
  ) => FormattedCMSData<Location> | undefined | null;
}

interface GreenHouseQueryData {
  greenhouseJobs: {jobs: JobProps[]};
}

const GH_INTERNSHIP_ID: number = process.env.GATSBY_GH_INTERNSHIP_DEPARTMENT_ID
  ? parseInt(process.env.GATSBY_GH_INTERNSHIP_DEPARTMENT_ID)
  : 4001469002;

const INTERNSHIP_DATA = {title: 'Internships.', id: '0001'};

const GH_EXECUTIVE_ID: number = process.env.GATSBY_GH_EXECUTIVE_DEPARTMENT_ID
  ? parseInt(process.env.GATSBY_GH_EXECUTIVE_DEPARTMENT_ID)
  : 4000278002;

const EXECUTIVE_DATA = {title: 'Executive.', id: '0002'};

const GREEN_HOUSE = graphql`
  query GREEN_HOUSE {
    greenhouseJobs: allGreenhouseJobs(filter: {id: {ne: "dummy"}}) {
      jobs: nodes {
        title
        content
        alternative_id
        updated_at
        departments {
          name
          alternative_id
        }
        offices {
          name
          alternative_id
        }
      }
    }
  }
`;

function findCMSData<T extends CMSPage>(
  list: T[],
  matchID: number,
): T | SpecialCasePage | undefined {
  if (matchID === GH_INTERNSHIP_ID) return INTERNSHIP_DATA;
  if (matchID === GH_EXECUTIVE_ID) return EXECUTIVE_DATA;
  return list.find(item =>
    item.ghid.find((id: string) => parseInt(id) === matchID),
  );
}

function findAllCMSData<T extends CMSPage>(
  list: T[],
  matchIDs: number[],
): (T | SpecialCasePage | undefined)[] {
  const matched: (T | SpecialCasePage | undefined)[] = [];
  matchIDs.forEach(matchID => {
    const result = findCMSData(list, matchID);
    if (result) matched.push(findCMSData(list, matchID));
  });
  return matched;
}

function formatCMSData<T extends CMSPage | SpecialCasePage>(
  list: T[],
  jobs: FormattedJob[],
  team = false,
): FormattedCMSData<T>[] {
  return list.map(item => ({
    ...item,
    id: item.id,
    name: item.title,
    text: item.title,
    value: item.id,
    slug: (item as {slug?: string}).slug ?? null,
    jobs: jobs.filter(({teamId, locationId}) =>
      team ? teamId === item.id : locationId === item.id,
    ),
  }));
}

function deDuplicateJobs(jobs: FormattedJob[]): UniqueJobProps[] {
  // Regroup jobs array by title so we can have a job with multiple locations

  const uniqueJobs = jobs.reduce((final: UniqueJobObject, job) => {
    const location = {
      location: job.location,
      locationId: job.locationId,
      locationPath: job.locationPath,
      address1: job.address1,
      address2: job.address2,
      abbreviation: job.abbreviation,
      phone: job.phone,
    };
    // final[job.title] = final[job.title] || {...job, locations: []};
    // final[job.title].locations.push(location);
    final[job.id] = final[job.id] || {...job, locations: []};
    final[job.id].locations.push(location);
    return final;
  }, {});

  // Just making sure to remove duplicate job locations
  const result = Object.values(uniqueJobs).map(job => {
    job.locations = job.locations.filter(
      (loc, index, locations) =>
        locations.findIndex(l => l.locationId === loc.locationId) == index,
    );
    return job;
  });
  return result;
}

function formatJob(
  job: JobProps,
  team: Team | undefined,
  location: (Location | undefined)[],
): FormattedJob {
  const {title, content, alternative_id: id, updated_at} = job;
  return {
    id,
    title,
    content,
    team: team ? team.title : '',
    teamId: team ? team.id : '',
    teamPath: team && team.slug ? `/careers/${team.slug}/` : '',
    location: location ? location.title : '',
    locationId: location ? location.id : '',
    locationPath: location && location.slug ? `/careers/${location.slug}/` : '',
    address1: location && location.address1,
    address2: location && location.address2,
    abbreviation: location && location.abbreviation,
    phone: location && location.phone,
    path: `/careers/jobs/${id}/`,
    updated_at: updated_at,
  };
}

function getAllJobs(
  list: JobProps[],
  allTeams: Team[],
  allLocations: Location[],
): FormattedData {
  const allJobs =
    list && list.length > 0
      ? list
          .filter(item => item)
          .flatMap((job: JobProps) => {
            const teamID = job.departments[0].alternative_id;
            const officeIDs = job.offices.map(office => office.alternative_id);
            const teamData = findCMSData(allTeams, teamID);
            const locationData = findAllCMSData(allLocations, officeIDs);
            if (locationData.length > 1) {
              return locationData.flatMap(location =>
                formatJob(job, teamData, location),
              );
            } else return formatJob(job, teamData, locationData[0]);
          })
      : [];

  /** Add Internship and Executive Teams to allTeams */
  const allTeamsWithSpecialCases = formatCMSData(
    [...allTeams, INTERNSHIP_DATA, EXECUTIVE_DATA],
    allJobs,
    true,
  ).sort((a, b) => alphaSort(a.name, b.name));

  /** Group team and location data */
  const teams = formatCMSData(allTeams, allJobs, true).sort((a, b) =>
    alphaSort(a.name, b.name),
  );
  const locations = formatCMSData(allLocations, allJobs).sort((a, b) =>
    alphaSort(a.name, b.name),
  );

  return {
    allJobs,
    teams,
    locations,
    allTeamsWithSpecialCases,
  };
}

/**
 * A hook that returns data from the greenhouse api
 * @return {object} - Returns an object with data objects and filtering functions
 * @property {object[]} allJobs - an array of job objects
 * @property {object[]} teams - an array of objects that contain
 *  team name and a list of jobs for that team.
 * @property {object[]} locations - an array of objects that contain
 *  location name and a list of jobs for that location.
 * @property {function} getJob - a function that takes a jobID and returns the
 * job object from the list of jobs
 * @property {function} getTeam - a function that takes a teamID and returns the
 * team object from the list of teams
 * @property {function} getLocation - a function that takes a locationID and
 * returns the location object from the list of locations
 */
export default function useGreenHouse(): GreenHouseData {
  const {greenhouseJobs} = useStaticQuery<GreenHouseQueryData>(GREEN_HOUSE);
  const jobsList = greenhouseJobs.jobs;
  const allTeams = useTeams();
  const allLocations = useLocations();

  let allJobs: FormattedJob[] = [];
  let teams: FormattedCMSData<Team>[] = [];
  let locations: FormattedCMSData<Location>[] = [];
  let allTeamsWithSpecialCases: FormattedCMSData<SpecialCasePage>[] = [];

  if (jobsList && allTeams && allLocations) {
    ({allJobs, teams, locations, allTeamsWithSpecialCases} = getAllJobs(
      jobsList,
      allTeams,
      allLocations,
    ));
  }

  allJobs = deDuplicateJobs(allJobs);

  teams = teams.map(team => {
    team.jobs = deDuplicateJobs(team.jobs);
    return team;
  });

  allTeamsWithSpecialCases = allTeamsWithSpecialCases.map(team => {
    team.jobs = deDuplicateJobs(team.jobs);
    return team;
  });

  const getJob = (jobID: number): FormattedJob | undefined | null => {
    if (allJobs) {
      const job = allJobs.find(job => job.id === jobID);
      if (job) {
        return job;
      } else {
        return null;
      }
    }
    return null;
  };

  const getTeam = (
    teamID: string,
  ): FormattedCMSData<Team> | undefined | null => {
    if (teams) {
      const team = teams.find(team => team.id === teamID);
      if (team) {
        return team;
      } else {
        return null;
      }
    }
    return null;
  };

  const getLocation = (
    locationID: string,
  ): FormattedCMSData<Location> | undefined | null => {
    if (locations) {
      const location = locations.find(location => location.id === locationID);
      if (location) {
        return location;
      } else {
        return null;
      }
    }
    return null;
  };

  return {
    allJobs,
    teams,
    locations,
    getJob,
    getTeam,
    getLocation,
    allTeamsWithSpecialCases,
  };
}
