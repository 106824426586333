import {graphql, useStaticQuery} from 'gatsby';

export interface Team {
  title: string;
  url: string;
  id: string;
  slug: string;
  ghid: string[];
  teamIcon: {
    url: string;
  };
  handwrittenVideo: {
    url: string;
  };
  handwrittenSvg: {
    url: string;
  };
}

/**
 * TODO:
 * Update query to reflect new icon set in the cms
 */
const TEAMS_QUERY = graphql`
  query TEAMS_QUERY {
    cms {
      pages(filter: {contentType: "twoupages.teampage"}) {
        ... on CMS_TeamPage {
          title
          url
          id
          slug
          ghid
          teamIcon {
            url
          }
          handwrittenVideo {
            url
          }
          handwrittenSvg {
            url
          }
        }
      }
    }
  }
`;

export default function useTeams(): Team[] | null {
  const data = useStaticQuery(TEAMS_QUERY);
  if (!data || !data.cms || !data.cms.pages) return null;
  return data.cms.pages;
}
